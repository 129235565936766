<script>
const { $pinia } = useNuxtApp()

const isvPiniaState = localStorage.getItem('isvPiniaState')

if (isvPiniaState) {
  $pinia.state.value = JSON.parse(isvPiniaState)
}

watch(
  $pinia.state,
  (state) => {
    // persist the whole state to the local storage whenever it changes
    localStorage.setItem('isvPiniaState', JSON.stringify(state))
  },
  { deep: true }
)
</script>

<template>
  <tairo-sidebar-layout>
    <!--    <BaseBreadcrumb />-->
    <slot />
  </tairo-sidebar-layout>
</template>

<style>
:root {
  --p-accordion-header-background: var(--color-muted-50) !important;
  --p-accordion-header-active-background: var(--color-primary-500) !important;
  --p-accordion-header-active-hover-background: var(--color-primary-500) !important;

  .p-accordionpanel:nth-of-type(odd) {
    --p-accordion-header-background: var(--color-muted-100) !important;
  }
}

html.dark {
  --p-accordion-header-background: var(--color-muted-800) !important;

  .p-accordionpanel:nth-of-type(odd) {
    --p-accordion-header-background: var(--color-muted-900) !important;
  }

  --p-accordion-content-background: var(--color-muted-800) !important;
  --p-tabs-tabpanel-background: var(--color-muted-800) !important;
  --p-tabs-tablist-background: var(--color-muted-800) !important;
}
</style>
